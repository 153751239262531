import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { sample, compact } from 'lodash'
import { Element, scroller } from 'react-scroll';
import moment from 'moment'

import { Layout, Block } from '../components'
import { container, padding, bgImage, hoverState, pagePadding, pageHeading, blockPadding } from '../styles/global'
import { legacyHeading1, legacyHeading2, subheading, h4, body } from '../styles/type'
import { yellow, slateGrey } from '../styles/colours'
import { Zoom } from 'swiper/js/swiper.esm'
import { renderPost } from '../components/Block/templates/BlockGridItem'
import { media } from '../styles/utils'

const DefaultPage = (props) => {
	const { data } = props.pageContext
	const [activeSlide, setActiveSlide] = useState(null)

	const changeHeroSlide = (slideIndex) => {
		setActiveSlide(slideIndex + 1)

		scroller.scrollTo('hero', {
			duration: 250,
			delay: 100,
			smooth: true,
		})
	}

	const renderHero = () => {
		return (
			<Hero
				name={'hero'}
			>
				<Info>
					<Heading
						as={'h1'}
						dangerouslySetInnerHTML={{__html: data?.hero_caption ? data?.hero_caption : data?.title}}
					/>
				</Info>

				<Block
					layout={'slider'}
					slides={data.hero_slider}
					sliderStyles={sliderStyles}
					activeSlide={activeSlide}
					lastBlock
					display
					controls
					breadcrumb={data?.title}
				/>
			</Hero>
		)
	}

	const renderContent = () => {
		return (
			<Content>
				{renderBlocks(data.blocks)}
			</Content>
		)		
	}

	const renderBlocks = (blocks) => {
        if (!blocks) return;
        
		return blocks.map((block, i) => { 
			const noContainer = ['image', 'image_slider']

			block.acf_fc_layout = block.acf_fc_layout || 'image_hotspot'

			return (
				<Block
					layout={block.acf_fc_layout}
					display={true}
					lastBlock={(blocks.length - 1) == i}
					key={i}
					wrapContainer={!noContainer.includes(block.acf_fc_layout)}
					{...block}
				/>
			)
		})
	}

	return (
		<Layout
			meta={data.seo}
		>
			<Wrapper>
				{/* <Block
					layout={'page_title'}
					breadcrumb={data.title}
					title={data.introduction}
					textColor={'black'}
					h1
					display
				/> */}
				{renderHero()}

				{renderContent()}
			</Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Item = styled.div``
const Items = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`


// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;

	/* Hotspot image override */

	.hotspot-img {
        max-width: 100vw;
    }
`

const Container = styled.div`
	${container}
	${blockPadding}
	${pagePadding}

	display: flex;
	flex-direction: column;
	max-width: 100%;
`

// Hero

const Hero = styled(Element)`
	position: relative;
	margin-bottom: 160px;

	${media.tablet`
        margin-bottom: 116px;
    `}

    ${media.phone`
        margin-bottom: 80px;
    `}

	${Info}{
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		${blockPadding}
		margin-bottom: 40px;

		${Heading} {
			font-size: 72px;
			color: white;
			text-transform: uppercase;
			letter-spacing: 0.05em;
			font-family: 'Formula';
			line-height: 1;

			${media.phone`
				font-size: 40px;
			`}
		}

		${Subheading} {
			${body}
			color: white;
			padding-bottom: 4px;
	
			${media.phone`
				display: none;
			`}
		}
	}

	${media.phone`
		.counter {
			bottom: -48px;
			color: black;
		}
	`}
`

// Slider

const sliderStyles = css`
	height: 100vh;
	width: 100vw;

	${media.phone`
		// height: 66vw;
		height: 75vh;
	`}
`

// Content

const Content = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.block_team_grid .block-container {
        max-width: 100%;
    }

	${Container} {
		> ${Subheading} {
			${pageHeading}

			${media.phone`
				margin-bottom: 47px;
			`}
		}

		${Description} {
			&, p {
				font-family: 'Formula';
				font-size: 72px;
				line-height: 1;
				letter-spacing: 0.05em;
				text-transform: uppercase;
				max-width: 1000px;

				${media.phone`
					font-size: 40px;
					line-height: 95%;
				`}
			}
		}
	}
`

export default DefaultPage